import "../../constants"
import React, { useEffect, useState, useRef } from "react"
import Layout from "../../components/layout"
import { useApi } from "../../hooks/useApi"
import {css} from "twin.macro"
import SEO from '../../components/seo'
import Button from '../../components/button'
import {
	faUpload,
	faCopy,
	faThumbsUp,
	faTimes,
	faTrashAlt
} from '@fortawesome/free-solid-svg-icons'

import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';

import File from './components/file'
import {DebounceInput} from 'react-debounce-input' 
import Clipboard from 'react-clipboard.js';
import Icon from '../../components/icon'
import AuthGate from "../../components/authGate"

const LinkCellRenderer = (props) => {
	const [showThumb, setShowThumb] = useState(false)
	const handleLinkCopySuccess = (event) => {
		setShowThumb(true)
		setTimeout( () => {
			setShowThumb(false)
		}, 1000)
	}
	let key
	if (props.value) {
		const keyParts = props.value.split('/')
		key = keyParts.slice(-1)[0]
	}
	return (key ? <span>
		{<Clipboard className="copy-download-url" tw="hidden" data-clipboard-text={props.value} component="div" onSuccess={handleLinkCopySuccess}>
			<Icon tw="text-blue h-4 mr-3 w-auto" icon={showThumb ? faThumbsUp : faCopy}/>
		</Clipboard>}
		<a target="_blank" rel="noopener noreferrer" href={props.value}>{props.value}</a>
	</span> : null)
}

const FilenameCellRenderer = (props) => {
	return props.data && props.data.key ? <span>
		<Icon 
			className="delete-file" 
			tw="hidden text-blue h-4 mr-3 w-auto" 
			icon={faTrashAlt} 
			data-key={props.data.key} 
			onClick={() => props.onDelete(props.data.key, props.data.name)}
		/>{props.value}
	</span> : null
}

const Me = () => {

	const [gridApi, setGridApi] = useState(null);
	const [showUpload, setShowUpload] = useState(false)
	const { callApi } = useApi() 
	const gridRef = useRef()
	const gridContainerRef = useRef()
	const gridAbsoluteRef = useRef()

	const dataSource = {
		getRows : (params) => {
			const sort = params.sortModel.length ? `${params.sortModel[0].colId}:${params.sortModel[0].sort}` : 'created:desc'
			let url = `?offset=${params.startRow}&limit=${params.endRow - params.startRow}&sort=${sort}`
			if (search.current) {
				url = url + `&search=${encodeURI(search.current)}`
			}
			callApi({
				method: 'get',
				namespace: url
			})
			.then((res) => {
				params.successCallback(res.data.nodes)
			})
			.catch((err) => {
				console.log(err)
			})
		}
	}
	
	const onGridReady = (params) => {
		
		setGridApi(params.api);

		params.api.setDatasource(dataSource);

	};

	const handleClickUpload = (event) => {
		event.preventDefault()
		setShowUpload(true)
	}

	// const [search, setSearch] = useState()
	const search = useRef(null)

	const handleSearch = (event) => {
		search.current = event.target.value
		gridApi.setDatasource(dataSource)
	}

	function dateFormatter(params) {
		return params.value && new Date(params.value).toLocaleString()
	}

	const [refresh, setRefresh] = useState(false)

	const handleDeleteFile = (key, name) => {
		if (window.confirm(`Are you sure you want to delete '${name}' (key ${key})?`)) {
			callApi({
				method: 'delete',
				namespace: `${key}`
			})
			.then((res) => {
				setRefresh(true)
			})
			.catch((err) => {
				if (err.response && err.response.data && err.response.data.message) {
					alert('Error deleting file: ' + err.response.data.message)
				}
				else {
					alert('Error deleting file.')
				}
				console.log(err)
			})
		}
	}

	useEffect( () => {
		if (refresh && gridApi) {
			gridApi.setDatasource(dataSource)
			setRefresh(false)
		}
	}, [refresh, gridApi, dataSource])

	const CheckboxCellRenderer = props => {
		return (
			Boolean(props.value) ? <span>&#10004;</span> : <span></span>
		);
	}

	const styles = css`
		.ag-row-focus .copy-download-url {
			display: inline-block;
			cursor: pointer;
		}
		.ag-row-hover .copy-download-url {
			display: inline-block;
			cursor: pointer;
		}
		.ag-row-focus .delete-file {
			display: inline-block;
			cursor: pointer;
		}
		.ag-row-hover .delete-file {
			display: inline-block;
			cursor: pointer;
		}
	`

	useEffect(() => {
		if(gridContainerRef.current && gridRef.current) {
			//gridRef.current.eGridDiv.style.height = `${gridContainerRef.current.clientHeight}px`
		}
	})

	useEffect(() => {
		const resizeObserver = new ResizeObserver((entries) => {
			if (gridContainerRef.current) {
				const rect = gridContainerRef.current.getBoundingClientRect()
				if (gridAbsoluteRef.current) {
					gridAbsoluteRef.current.style.height = `${rect.height}px`
					gridAbsoluteRef.current.style.width = `${rect.width}px`
					gridAbsoluteRef.current.style.left = `${rect.left}px`
					gridAbsoluteRef.current.style.top = `${rect.top}px`
				}
			}
		});

		if (gridContainerRef.current) {
			resizeObserver.observe(gridContainerRef.current);
		}

		return () => {
			if (gridContainerRef.current) {
				resizeObserver.unobserve(gridContainerRef.current);
			}
		}

	});

	const grid = <>
		<div id="grid-container" tw="flex-1 h-full flex flex-col" css={styles}>
			<div tw="grid grid-cols-4 gap-2 items-start">
				<div tw="col-span-3 flex items-center">
					<DebounceInput
						tw="inline!"
						placeholder="Search by file name or key"
						minLength={2}
						debounceTimeout={300}
						value={search.current}
						onChange={handleSearch}
					></DebounceInput>{search.current && <Icon tw="inline h-7 -mt-3 ml-2 mr-2" icon={faTimes} onClick={(event) => {
						search.current = ''
						gridApi.setDatasource(dataSource)
					}}/>}
				</div>
				<Button tw="" special="true" icon={faUpload} onClick={handleClickUpload}>Upload</Button>
			</div>
			<div ref={gridContainerRef} tw="h-0 flex-1">

			</div>
			<div ref={gridAbsoluteRef} tw="absolute w-full" className="ag-theme-alpine">
				<AgGridReact
						ref={gridRef}
						modules={[InfiniteRowModelModule]}
						rowBuffer={0}
						rowModelType={'infinite'}
						paginationPageSize={100}
						cacheOverflowSize={2}
						cacheBlockSize={100}
						maxConcurrentDatasourceRequests={1}
						infiniteInitialRowCount={1000}
						maxBlocksInCache={10}
						onGridReady={onGridReady}
						enableCellTextSelection={true}
						ensureDomOrder={true}
					>
						<AgGridColumn field="name" sortable={true} resizable={true} cellRendererFramework={FilenameCellRenderer} cellRendererParams={{onDelete:handleDeleteFile}}></AgGridColumn>
						<AgGridColumn field="link" sortable={true} resizable={true} cellRendererFramework={LinkCellRenderer}></AgGridColumn>
						<AgGridColumn field="maxDownloads" sortable={true} resizable={true} type="numericColumn"></AgGridColumn>
						<AgGridColumn field="autoDelete" sortable={true} resizable={true} cellRendererFramework={CheckboxCellRenderer}></AgGridColumn>
						<AgGridColumn field="expires" sortable={true} resizable={true} valueFormatter={dateFormatter}></AgGridColumn>
						<AgGridColumn field="created" sortable={true} resizable={true} valueFormatter={dateFormatter}></AgGridColumn>
						<AgGridColumn field="modified" sortable={true} resizable={true} valueFormatter={dateFormatter}></AgGridColumn>
				</AgGridReact>
			</div>
		</div>
	</>

	const upload = <File autoStart="true" onClose={() => {setShowUpload(false)}}/>

	return <div id="my-files" tw="flex-1 flex flex-col p-8">
		<div tw="h-full w-full md:hidden">This feature is not yet available at this screen size.</div>
		<div id="my-files-container" tw="flex-1 h-full w-full hidden md:flex flex-col">
			{ showUpload ? upload : grid }
		</div>
		
	</div>
	
}

const MeWithLayout = (props) => {
	return <AuthGate {...props}>
		<Layout>
			<SEO title="My Files"/>
			<Me/>
		</Layout>
	</AuthGate>
}

export default MeWithLayout